$border-color-lt: rgba(135, 150, 165, 0.075);
$border-color-def:	 	rgba(135, 150, 165, 0.1);
$table-border-color: rgba(135, 150, 165, 0.1);
$table-striped-color: rgba(0, 0, 0, 0.025);
$table-light-gray: #ececed;
$list-group-border: $border-color-lt;
$bg-color-blue: #f7f8fa;
$custom-dark-gray: #647787;
$light-text-color: #a7afbb;
$full-white: rgba(255, 255, 255, 1);
$primary: #137eff;
$min-primary: rgba(19, 126, 255, 0.05);
$info: #7258ff;
$success: #5bc146;
$warning: #ffd14d;
$danger: #fe4d62;
