@import 'variables';

.bg-white {
  .custom-table {
    td {
      background-color: #f7f8fa;
    }
  }

  th {
    background-color: white;
  }
}

.custom-table {
  // Loading overlay
  .p-datatable-loading-overlay.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  // Can be used to create a 'show more' button or text in <i> tags (notifications)
  tr td .showmore {
    i, br {
      display: none;
    }

    &.show {
      i {
        display: inherit;
      }
    }
  }

  // Table content
  .p-datatable-table {
    font-family: poppins-regular;
    border-collapse: separate !important;
    border-spacing: 0 10px !important;

    .p-datatable-tbody td {
      border-color: transparent !important;
      border-width: 0;
      vertical-align: middle;
      box-shadow: 0 1px 0 $border-color-lt, 0 -1px 0 $border-color-lt;

      &:first-child {
        border-radius: .25rem 0 0 .25rem;
      }

      &:last-child {
        border-radius: 0 .25rem .25rem 0;
      }
    }

    tr {
      &.clickablerow:hover {
        cursor: pointer;

        td {
          background-color: $min-primary;
        }
      }

      &.staticrow {
        background-color: $table-light-gray;
        &:hover {
          cursor: default;
        }
      }
    }

    .p-datatable-thead {
      font-size: 0.7rem;

      tr {

        &.unread {
          td {
            background-color: rgba(19, 126, 255, 0.05);
          }
        }

        &.ui-state-highlight {
          font-weight: 600;
        }

        &.danger {
          td {
            background-color: $danger !important;
            color: $full-white;
          }

        }

        th {
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          border-width: 0;
          color: $light-text-color !important;
          cursor: pointer;

          &.p-sortable-column:focus {
            box-shadow: none;
          }

          .p-sortable-column-icon {
            color: $light-text-color;
            font-size: 0.7rem;
          }

          background-color: rgb(0, 0, 0, 0);
        }
      }
    }

    .ui-table {
      position: relative;
    }


    .iconcolumn {
      width: 30px;
    }

    .buttoncolumn {
      white-space: nowrap;
    }
  }


  // Paginator
  .p-paginator-bottom.p-paginator {
    border: none;
    text-align: center;
    background: transparent;

    .p-paginator-element {
      background-color: transparent;

      &.p-highlight {
        background: $primary;
        color: white;
      }
    }

    button {
      height: 1.5rem;
      width: 2rem;
      min-width: 1.5rem;
      border-radius: 3px;
      border: none;
      font-family: poppins-regular;
      font-size: 1rem;
    }

    .ui-paginator-icon, .ui-paginator-page {
      padding: 0px 10px;

      &.ui-state-active {
        background-color: #0d47bc;
        color: $full-white !important;
        border-radius: 3px;
      }
    }

    .p-disabled {
      color: $light-text-color;
      opacity: 0.4;
      cursor: default;

      &:hover {
        color: $light-text-color;
      }
    }

    p-dropdown {
      input, .ui-helper-hidden-accessible, .ui-dropdown, .ui-dropdown-label-container {
        max-width: 70px;
        min-width: 50px;
      }

      .ui-dropdown-label {
        padding: 1px 2px;
      }
    }
  }
}





